import {getWorkerWrapperIframeUrlOverride, getWorkerUrlOverride} from './createLocalWorker'

const addIFrameToDOM = iframe => {
    if (window.document.readyState !== 'loading') {
        prependIframeToBody()
    } else {
        window.document.addEventListener('DOMContentLoaded', () => {
            prependIframeToBody()
        })
    }

    function prependIframeToBody() {
        const body = window.document.body
        body.insertBefore(iframe, body.firstChild)
    }
}

window.iframeMessages = []

module.exports = (isDebug, isInSSR, boltBase) => {
    const _ = require('lodash')
    const experimental_state = {
        iframe: null,
        iframeUrl: null,
        isIframeReady: false,
        queuedMessages: [],
        bufferedMessagesByContextId: {},
        eventListenerByContextId: {},
        window_message_registered: false // to be removed once experiment is merged
    }

    const experimental_removeIFrameFromDOM = () => {
        window.document.body.removeChild(experimental_state.iframe)

        experimental_state.iframeUrl = null
        experimental_state.isIframeReady = false
        experimental_state.queuedMessages = []
    }

    const experimental_isIFrameEvent = message => experimental_state.iframe ? message.source === experimental_state.iframe.contentWindow : false

    const experimental_handleIFrameMessages = message => {
        if (!experimental_isIFrameEvent(message)) {
            return
        }

        if (message.data.type === 'IFRAME_LOADED') {
            experimental_state.isIframeReady = true
            experimental_state.queuedMessages.forEach(obj => obj.postMessage(obj.message, obj.transfer))
            experimental_state.queuedMessages = []
            return
        }
        if (isDebug) {
            const copy = JSON.parse(JSON.stringify(message.data))

            window.iframeMessages = window.iframeMessages || []
            window.iframeMessages.push({direction: 'INCOMING', ...copy, timestamp: performance.now()})
        }

        const contextId = _.get(message, 'data.__messageContextId')
        const eventListener = _.get(experimental_state.eventListenerByContextId, contextId)
        if (!eventListener) {
            let bufferedMessages = _.get(experimental_state.bufferedMessagesByContextId, contextId)

            if (_.isNil(bufferedMessages)) {
                bufferedMessages = []
                _.set(experimental_state.bufferedMessagesByContextId, contextId, bufferedMessages)
            }

            bufferedMessages.push(message)
        } else {
            _.invoke(experimental_state.eventListenerByContextId, contextId, message)
        }
    }

    const experimental_createIframe = (url, workerUrl) => {
        if (url === experimental_state.iframeUrl) {
            return
        } else if (experimental_state.iframe) {
            experimental_removeIFrameFromDOM()
        }

        experimental_state.iframeUrl = url
        experimental_state.iframe = window.document.createElement('iframe')
        // The iframe is intentially visible since it may improve cpu/network priority for it
        experimental_state.iframe.style.cssText = 'position: fixed; left: 0; right: 0; top: 0; bottom: 0; width: 1px; height: 1px; background: transparent; border: 0'
        experimental_state.iframe.tabIndex = -1
        experimental_state.iframe.setAttribute('aria-hidden', 'true')
        const iframeUrlOverride = getWorkerWrapperIframeUrlOverride(isInSSR, boltBase)
        const workerUrlOverride = getWorkerUrlOverride(isInSSR, boltBase)

        if (iframeUrlOverride && workerUrlOverride) {
            url = iframeUrlOverride
            workerUrl = workerUrlOverride
        }

        experimental_state.iframe.src = `${url}?workerUrl=${workerUrl}&isDebug=${isDebug}&experimental=true`

        addIFrameToDOM(experimental_state.iframe)
    }

    return async (url, workerUrl, setIframeWorkerWrapper, contextId, isExperimentalIframeWorkerWrapper) => {
        if (!contextId || !url) {
            return
        }

        const old_state = {
            alreadyInitiated: false,
            isIframeReady: false,
            iFrame: null,
            listener: null,
            bufferedMessages: [],
            queuedMessages: []
        }

        const init = () => {
            if (isExperimentalIframeWorkerWrapper) {
                const postMessage = (message, transfer) => {
                    if (message) {
                        if (experimental_state.isIframeReady) {
                            experimental_state.iframe.contentWindow.postMessage({...message, __messageContextId: contextId}, '*', transfer)
                            if (isDebug) {
                                const clone = JSON.parse(JSON.stringify(message))

                                window.iframeMessages = window.iframeMessages || []
                                window.iframeMessages.push({direction: 'OUTGOING', ...clone, arg1: transfer, timestamp: performance.now(), contextId})
                            }
                        } else {
                            experimental_state.queuedMessages.push({message, postMessage, transfer})
                        }
                    }
                }

                const terminate = () => {
                    postMessage({type: 'TERMINATE'})
                    delete experimental_state.eventListenerByContextId[contextId]
                }

                const addEventListener = (type, handler) => {
                    if (type !== 'message') {
                        throw new Error('cannot add event listener to message type which is not message')
                    }

                    experimental_state.eventListenerByContextId[contextId] = handler

                    const bufferedMessages = _.get(experimental_state.bufferedMessagesByContextId, contextId)

                    if (!_.isNil(bufferedMessages)) {
                        bufferedMessages.forEach(message => {
                            _.invoke(experimental_state.eventListenerByContextId, contextId, message)
                        })

                        delete experimental_state.bufferedMessagesByContextId[contextId]
                    }
                }

                if (!experimental_state.window_message_registered) { // move this entire chunk outside of this scope once experiment is merged
                    window.addEventListener('message', experimental_handleIFrameMessages, false)
                    experimental_state.window_message_registered = true
                }
                experimental_createIframe(url, workerUrl)
                postMessage({type: 'INIT'})

                setIframeWorkerWrapper({
                    postMessage,
                    terminate,
                    addEventListener
                })
            } else {
                const terminate = () => {
                    if (old_state.iFrame) {
                        old_state.iFrame.parentNode.removeChild(old_state.iFrame)
                        old_state.alreadyInitiated = false
                        old_state.iFrame = undefined
                        old_state.isIframeReady = false
                    }
                }

                const postMessage = (message, transfer) => {
                    if (message) {
                        if (old_state.isIframeReady) {
                            old_state.iFrame.contentWindow.postMessage(message, '*', transfer)
                            if (isDebug) {
                                const clone = JSON.parse(JSON.stringify(message))
                                window.iframeMessages.push({direction: 'OUTGOING', ...clone, arg1: transfer, timestamp: performance.now()})
                            }
                        } else {
                            old_state.queuedMessages.push({message, transfer})
                        }
                    }
                }

                const addEventListener = (type, handler) => {
                    if (type !== 'message') {
                        throw new Error('cannot add event listener to message type which is not message')
                    }
                    if (old_state.listener !== null) {
                        console.warn('cannot add event listener twice')
                        return
                    }

                    if (isDebug) {
                        console.log('bufferedMessages count', old_state.bufferedMessages.length)
                    }

                    old_state.bufferedMessages.forEach(message => handler(message))
                    old_state.bufferedMessages = null

                    old_state.listener = handler
                }


                const isIFrameEvent = message => old_state.iFrame ? message.source === old_state.iFrame.contentWindow : false

                const handleIFrameMessages = message => {
                    if (!isIFrameEvent(message)) {
                        return
                    }
                    if (message.data.type === 'IFRAME_LOADED') {
                        old_state.isIframeReady = true
                        old_state.queuedMessages.forEach(obj => postMessage(obj.message, obj.transfer))
                        old_state.queuedMessages = []
                        return
                    }
                    if (isDebug) {
                        const copy = JSON.parse(JSON.stringify(message.data))
                        window.iframeMessages.push({direction: 'INCOMING', ...copy, timestamp: performance.now()})
                    }

                    if (old_state.listener) {
                        old_state.listener(message)
                        return
                    }
                    old_state.bufferedMessages.push(message)
                }

                const iframesWithTheSameContext = window.document.querySelectorAll(`[contextId="${contextId}"]`)
                if (old_state.alreadyInitiated || iframesWithTheSameContext.length > 0) {
                    return
                }
                old_state.iFrame = window.document.createElement('iframe')
                // The iframe is intentially visible since it may improve cpu/network priority for it
                old_state.iFrame.style.cssText = 'position: fixed; left: 0; right: 0; top: 0; bottom: 0; width: 1px; height: 1px; background: transparent; border: 0'
                old_state.iFrame.tabIndex = -1
                old_state.iFrame.setAttribute('aria-hidden', 'true')
                old_state.iFrame.setAttribute('contextId', contextId)
                old_state.iFrame.src = `${url}?workerUrl=${workerUrl}&isDebug=${isDebug}`

                addIFrameToDOM(old_state.iFrame)
                window.addEventListener('message', handleIFrameMessages, false)
                old_state.alreadyInitiated = true
                setIframeWorkerWrapper({
                    postMessage,
                    terminate,
                    addEventListener
                })
            }
        }

        init(url)
    }
}
